import React from 'react'
import {Container} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import ConfirmOrderForm from '../components/Forms/confirmOrder'
import * as styles from './styles/pages.module.css'

const ConfirmPage = ({location}) => {
	return (
		<Layout location={location}>
			<Seo title={"Confirm Order"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<div className={styles.centerContent}>
					<ConfirmOrderForm/>
				</div>
			</Container>
		</Layout>
	)
}

export default ConfirmPage